@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "@angular/cdk/overlay-prebuilt.css";

* {
  font-family: "IBM Plex Sans", sans-serif;
  font-display: block;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  letter-spacing: normal;
  background-color: #f6f6f6;
  height: 100%;
  color: #141414;
}

input[type="password"] {
  padding-right: 3rem !important;
}

.plan-detail-container {
  @apply flex scale-90 origin-top flex-col bg-[#EEEEEE] rounded-lg border-[#E2E2E2] border-[1px];
}

.plan-detail-list {
  @apply flex flex-col gap-3 p-6 pt-3 bg-white rounded-t-xl;
}

.material-symbols-outlined.fill {
  font-variation-settings: "FILL" 1, "wght" 600, "GRAD" 0, "opsz" 48;
}

//Table
table {
  @apply flex w-full flex-col;

  thead {
    @apply flex w-full;

    tr {
      @apply w-full flex py-5 px-6 justify-self-stretch;

      th {
        @apply text-start font-[450] text-sm text-[#545454];
      }
    }
  }

  tbody {
    @apply flex w-full flex-col;

    tr {
      @apply w-full flex py-3 px-6 justify-self-stretch min-h-[76px] cursor-pointer items-center hover:bg-[#F3F3F3];
      td .action-menu {
        visibility: hidden;
      }
      &:hover td .action-menu {
        visibility: visible;
      }
    }
  }
}

// Scroll
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// Slider
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 32px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #7b61ff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #7b61ff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Printing related Task */
khatapana-table-print {
  display: none;
}

khatapana-transaction-table {
  position: relative;
}

@media print {
  @page {
    margin: 0;
  }
  khatapana-table-print {
    display: block;
  }
  .khatapana-transaction-table,
  .action-menu {
    display: none;
  }
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/datepicker';
